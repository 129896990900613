<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add Vitamin and Supplements</h1>
    </div>
    <vitamin-supplement-form
      button-text="Save"
      :loaders="loaders.vitaminSupplement"
      @storeVitaminSupplement="storeVitaminSupplement"
    />
  </div>
</template>

<script>
import VitaminSupplementForm from "@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplement/VitaminSupplementForm.vue";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
export default {
  components :{
    VitaminSupplementForm,BackButton
  },
  name: "StoreVitaminSupplement",
  data() {
    return {
      loaders: {
        vitaminSupplement : false,
      },
      response: {
        categories: [],
      },
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    storeVitaminSupplement(event) {
      let vm = this;
      vm.loaders.vitaminSupplement = true
      let formData = new FormData();
      _.each(event, (value, key) => {
        formData.append(key, value);
      });

      const config = {
        headers: {'content-type': 'multipart/form-data'}
      };

      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement/store`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Vitamin & Supplements created successfully.'
        });
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => vm.loaders.vitaminSupplement = false)
    },
  },

}
</script>

<style scoped>

</style>
