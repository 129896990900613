<template>
  <div v-loading="loaders.vitaminSupplement" class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Vitamin and Supplement Manager</h1>
      <div class="d-flex justify-content-end align-items-center">
        <router-link class="ml-2" :to="{ name: 'VitaminSupplementCategory'}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font" type="secondary">
            Category
          </el-button>
        </router-link>
        <router-link class="ml-2" :to="{ name: 'VitaminSupplementSubCategory'}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Sub Category
          </el-button>
        </router-link>
        <el-button class="ml-2 white-outline-button title-btn-padding button-font status-indicator button-hover-effect" :class="indicatorClass" @click="modals.addData.visibility = true">
          <span class="pl-2">Add From URL</span>
        </el-button>
        <router-link class="ml-2 " :to="{ name: 'StoreVitaminSupplement'}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>

      </div>

    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.vitaminSupplement"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.vitaminSupplement"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.vitaminSupplement"
        >Deactivate
        </base-button>
      </div>
      <el-table-draggable
        handle=".handle"
        @drop="updateSortList"
      >
        <el-table
          class="table-responsive table-flush checklist-items-table checklist-table"
          header-row-class-name="thead-light"
          width="100%"
          ref="multipleSelectionTable"
          :data="response.vitaminSupplements"
          :row-class-name="row"
          @selection-change="vitaminSupplementsMultiSelect"
        >
          <el-table-column align="left" width="50">
            <template slot-scope="scope">
              <div class="handle" v-if="scope.row.id !==''">
                <img src="/img/icons/buttons/handle.svg" alt="+">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="selected" type="selection" min-width="130px">
          </el-table-column>
          <el-table-column label="NAME" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.title}}</div>
            </template>
          </el-table-column>
          <el-table-column label="Category" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.vitamin_supplement_category ? scope.row.vitamin_supplement_category.name : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Sub Category" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.vitamin_supplement_sub_category ? scope.row.vitamin_supplement_sub_category.name : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status" min-width="130px">
            <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS">
            <div slot-scope="{ $index, row }" class="d-flex">
              <router-link :to="{name: 'ShowVitaminSupplement', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
              <router-link :to="{name: 'EditVitaminSupplement', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>

              <img
                class="pointer"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="modals.removeModal.text"
        :removeId="modals.removeModal.id"
        @onRemove="removeVitaminSupplement"
        @onRemoveMultiple="deleteVitaminSupplement"
      />
      <add-url-modal
        title="Add data from the web"
        :modal-visible="modals.addData.visibility"
        :form-data="modals.addData.form"
        :loading="modals.addData.loading"
        ref="faqModal"
        @submit="handleSubmit"
        @close="modals.addData.visibility = false"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import AddUrlModal from "@/views/Components/Modals/AddUrlModal.vue";


const RemoveModal = () => import(/* webpackChunkName: "vitaminSupplementRemoveModal" */ "@/components/Modals/RemoveModal.vue");
import ElTableDraggable from 'element-ui-el-table-draggable';

export default {
  name: "VitaminSupplement",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal, AddUrlModal,
    ElTableDraggable,
  },
  mounted() {
    this.fetchVitaminSupplements()
    this.fetchVitaminSupplementMenuDataRequest()
  },
  data() {
    return {
      loaders: {
        vitaminSupplement: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      modals: {
        addData: {
          form:{
            url: '',
            is_facebook: true,
          },
          visibility: false,
          loading: false
        },
        removeModal: {
          text: '',
          id: '',
        }
      },
      selectedRows: [],
      response: {
        vitaminSupplements: [],
      },
      btnDraggable:'btnDraggable',
    };
  },
  methods: {

    fetchVitaminSupplements() {
      let vm = this;
      vm.loaders.vitaminSupplement = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/vitamin-supplement')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.vitaminSupplements = response.data.data;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.vitaminSupplement = false);
    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    handleSubmit({is_facebook,url}) {
      const obj = {is_facebook,url}
      this.storeVitaminSupplementMenuDataRequest(obj)
      this.modals.addData.visibility = false
    },

    fetchVitaminSupplementMenuDataRequest(){
      this.modals.addData.loading = true
      axios.get(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement/menu-data/get`)
        .then((response)=> {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.modals.addData.form = response.data.data ?? this.modals.addData.form
        })
        .catch((error) => console.log(error))
        .finally(()=>this.modals.addData.loading = false)
    },
    storeVitaminSupplementMenuDataRequest(data){
      this.modals.addData.loading = true
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement/menu-data/create`,data)
        .then((response)=> {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'Updated Successfully.'
          })
          this.fetchVitaminSupplementMenuDataRequest();
        })
        .finally(()=>this.modals.addData.loading = false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    vitaminSupplementsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    statusUpdateRequest(ids, status) {
      this.loaders.vitaminSupplement = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement/toggle-status`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.modals.removeModal.id = []
          this.clearSelection()
          this.fetchVitaminSupplements()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.vitaminSupplement = false
        })
    },

    row({row, rowIndex}) {
      return "handle";
    },

    // This function accept the arguments and returns new order key
    assignNewOrderKey(oldOrderKey, prev, curr, next){
      let newOrderKey
      if (prev === '') {
        if (curr < next || next === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = next
        }
      } else if ( next === '') {
        if (curr > prev || prev === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = prev
        }
      } else if (curr < prev && curr < next) {
        newOrderKey = prev
      } else if (curr > prev && curr > next) {
        newOrderKey = next
      } else if (curr > prev && curr < next) {
        newOrderKey = oldOrderKey
      }
      return newOrderKey
    },

    // This function returns counts of 0 order keys
    orderCheck(list) {
      let orderCheckCounter = 0
      for (const item of list) {
        if (item.order_key === 0) {
          orderCheckCounter++
          if (orderCheckCounter > 1) { break }
        }
      }
      return orderCheckCounter;
    },

    //This function returns payload based on defined conditions
    assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event) {
      let payload
      if (orderCheckCounter > 1) {
        let orderKeyArray = []
        let order = 1;
        for (const item of event.list) {
          orderKeyArray.push({ id: item.id, order_key: order }) // Array having order set from Event
          order++
        }
        payload = { orderKeyArray }
      } else {
        payload = { id: event.targetObject.id, newOrderKey, oldOrderKey }
      }
      return payload
    },

    // This function updates the Order of the table list
    async updateSortList(event){
      let vm = this
      let oldOrderKey = event.targetObject.order_key
      let newOrderKey
      let currIndex = event.list.findIndex(item => item.id === event.targetObject.id)
      let curr = event.list.find(item => item.id === event.targetObject.id).order_key
      let prev = (event.list[currIndex - 1]) ? event.list[currIndex - 1].order_key : ''
      let next = (event.list[currIndex + 1]) ? event.list[currIndex + 1].order_key : ''

      // This function assigns new order key based on defined conditions
      newOrderKey = this.assignNewOrderKey(oldOrderKey, prev, curr, next)

      let orderCheckCounter = 0 // Counter to check number of 0's in order

      // Pass list and counter to get counts of 0 order key
      orderCheckCounter = this.orderCheck(event.list)

      // Check if item is not drag to other position
      if (newOrderKey === oldOrderKey && orderCheckCounter < 2) { return }

      let payload // Initializing payload

      //This function assigns the payload based on defined conditions
      payload = this.assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event)

      const dispatchPayload = {
        contentMenu: 'vitamin-supplement',
        payload
      }

      try{
        const response = await vm.$store.dispatch('_updateSortList', dispatchPayload); // Dispatch Api call to update sort list
        vm.response.vitaminSupplements = response.data.data
      }
      catch(error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Vitamin and Supplements',
          message: message
        });
      }
    },

    handleDelete(index, row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.modals.removeModal.text = 'You want to remove this VitaminSupplement.';
      this.modals.removeModal.id = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.modals.removeModal.text = 'You want to remove these VitaminSupplements.';
      this.modals.removeModal.id = this.extractSelectedIds;
    },

    removeVitaminSupplement(id) {
      this.deleteRequest(id)
    },

    deleteVitaminSupplement() {
      this.deleteRequest(this.modals.removeModal.id)
    },

    deleteRequest(data) {
      this.loaders.vitaminSupplement = true
      this.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement/destroy`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.modals.removeModal.id = [];
          this.fetchVitaminSupplements();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false;
          this.loaders.vitaminSupplement = false;
        })
    },

    // changePage(page){
    //   if(page != this.request.pagination.page ) {
    //     this.request.pagination.page = page;
    //     this.fetchVitaminSupplements();
    //   }
    // },
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
    indicatorClass(){
      return (this.modals.addData.form.is_facebook)?'active':'inactive'
    }
  }
}
</script>

<style scoped>

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.handle{
  cursor: grabbing;
}

</style>

<style>
.instructions-sub-category-dropdown.el-button--primary{
  color: unset;
  border-color: unset;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 4px;
  border: none;
}

.instructions-sub-category-dropdown.el-button--primary{
  border: none;
}

</style>

<style>
.el-dropdown-menu__item.instructions-sub-category-dropdown-item a{
  font: normal normal normal 12px/17px Open Sans;
  color: #172B4D;
}

li.el-dropdown-menu__item.instructions-sub-category-dropdown-item{
  padding: 0.25rem 1.75rem;
}

</style>
