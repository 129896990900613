import { render, staticRenderFns } from "./EditVitaminSupplement.vue?vue&type=template&id=5c81aee5&scoped=true&"
import script from "./EditVitaminSupplement.vue?vue&type=script&lang=js&"
export * from "./EditVitaminSupplement.vue?vue&type=script&lang=js&"
import style1 from "./EditVitaminSupplement.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c81aee5",
  null
  
)

export default component.exports